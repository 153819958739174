import React, { useEffect, useState } from 'react';
import { Card, Col, Input, Row, Typography, Spin, Badge } from 'antd';
import { baseurl } from '../../components/lib/settings';
import { format } from 'date-fns';
import './AdminAmountReportsByDelegationCompany.css';
import { LoadingOutlined } from '@ant-design/icons';

const { Meta } = Card;
const { Search } = Input;
const { Title } = Typography;

function AdminAmountReportsByDelegationCompany() {
  const [groupedData, setGroupedData] = useState({});
  const [filteredData, setFilteredData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resp = await baseurl.post("v3/ngoma/administrator/financing/by_delegations/");
      const data = resp.data;
      const groupedByYear = data.reduce((acc, item) => {
        const year = new Date(item.first_vote_date).getFullYear();
        if (!acc[year]) acc[year] = [];
        acc[year].push(item);
        return acc;
      }, {});

      setGroupedData(groupedByYear);
      setFilteredData(groupedByYear);
    } catch (e) {
      console.error('Failed to fetch data:', e);
    } finally {
      setIsLoading(false);
    }
  };

  const formatToMetical = (amount) => {
    return amount.toLocaleString('pt-BR', { style: 'currency', currency: 'MZN' });
  };

  const formatStartDate = (formatDate) => {
    return format(new Date(formatDate), "dd/MM/yyyy");
  };

  const formatEndDate = (formatDate) => {
    return format(new Date(formatDate), "dd/MM/yyyy");
  };

  const handleSearch = (value) => {
    setSearchTerm(value.toLowerCase());
    const filtered = {};
    Object.keys(groupedData).forEach(year => {
      filtered[year] = (groupedData[year] || []).filter(item =>
        item.delegation_description && item.delegation_description.toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  return (
    <div className='query-query-container'>
      <br />
      <Title
        level={2}
        style={{
          textAlign: 'center',
        }}
      >
        Total acumulado por delegação
      </Title>

      <Search
        placeholder="Pesquisar por descrição da delegação"
        onChange={(e) => handleSearch(e.target.value)}
        style={{ marginBottom: 16 }}
      />
      <span className='warning-info'>* Os dados são agrupados em ordem crescente do valor total acumulado por cada delegação.</span>

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 40,
                }}
                spin
              />
            }
          />
        </div>
      ) : (
        <>
          {Object.keys(filteredData).sort((a, b) => b - a).map(year => {
            const sortedItems = [...filteredData[year]].sort(
              (a, b) => (b.delegation_total_amount || 0) - (a.delegation_total_amount || 0)
            );

            const yearTotal = sortedItems.reduce((acc, item) => acc + (item.delegation_total_amount || 0), 0);

            return (
              <div key={year} className="year-section">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                  <h2>{year}</h2>
                  <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    {`Total: ${formatToMetical(yearTotal)}`}
                  </span>
                </div>
                <Card style={{ backgroundColor: "#eeeeee", marginBottom: "20px" }}>
                  <Row gutter={[24, 24]}>
                    {sortedItems.map((item, index) => (
                      <Col key={index} xs={24} sm={12} md={8} lg={6}>
                        <Card
                          hoverable
                          title={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <span>{item.delegation_description || "Descrição não disponível"}</span>
                              <Badge
                                count={item.is_active ? 'Em curso' : 'Encerrado'}
                                style={{
                                  backgroundColor: item.is_active ? 'green' : 'red',
                                  color: 'white',
                                  fontSize: '12px',
                                  height: '25px',
                                  lineHeight: '25px',
                                  textAlign: 'center',
                                }}
                              />
                            </div>
                          }
                          style={{ width: "100%" }}
                          cover={
                            <div className="date-cover">
                              De {formatStartDate(item.first_vote_date)} a {formatEndDate(item.last_vote_date)}
                              <div className="operator-totals">
                                <p>&nbsp;&nbsp;M-Pesa: {formatToMetical(item.operator_totals.mpesa || 0)}</p>
                                <p>&nbsp;&nbsp;E-mola: {formatToMetical(item.operator_totals.emola || 0)}</p>
                                <p>&nbsp;&nbsp;Mkesh: {formatToMetical(item.operator_totals.mkesh || 0)}</p>
                              </div>
                            </div>
                          }
                        >
                          <Meta className='moneyCss'
                            description={`Total: ${formatToMetical(item.delegation_total_amount || 0)}`}
                          />
                        </Card>
                      </Col>
                    ))}
                  </Row>

                </Card>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default AdminAmountReportsByDelegationCompany;
