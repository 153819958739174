import React from 'react'
import { Layout, theme } from 'antd';

function NewContentLayout({ children }) {
    const { Content } = Layout;
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    return (
        <Content
            style={{
                margin: '24px 16px 0',
                overflow: 'initial',
            }}
        >
            <div
                style={{
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                {children}
            </div>
        </Content>
    )
}

export default NewContentLayout