import React, { useEffect, useState } from 'react';
import { Layout, Button, theme, Menu, Dropdown, Avatar, Modal, Spin } from 'antd';
import { LoadingOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, ProfileOutlined, SettingOutlined } from '@ant-design/icons';
import { baseurl } from '../lib/settings';

function Navbar({ collapsed, setCollapsed }) {
  const { Header } = Layout;
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [userNameLoading, setUserNameLoading] = useState(true);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const fetchName = () => {
    setUserNameLoading(true);
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      console.log("Fetched user from localStorage:", user);
      if (user && user.user_name) {
        setUserName(user.user_name);
      } else {
        console.log("No user name found in localStorage");
        setUserName("Nome não disponível");
      }
    } catch (error) {
      console.error("Failed to fetch username:", error);
      setUserName("Erro ao carregar nome");
    } finally {
      setUserNameLoading(false);
    }
  };

  useEffect(() => {
    fetchName();

    const handleStorageChange = (event) => {
      if (event.key === 'user') {
        fetchName();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const doLogout = async () => {
    setModalVisible(true);
    setIsLoading(true);
    try {
      await baseurl.post("auth/logout-session/");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.replace("/");
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setIsLoading(false);
      setModalVisible(false);
    }
  };

  const menuOptions = (
    <Menu>
      <Menu.Item disabled key="1" icon={<ProfileOutlined />}>Perfil</Menu.Item>
      <Menu.Item disabled key="2" icon={<SettingOutlined />}>Definições</Menu.Item>
      <Menu.Item key="3" icon={<LogoutOutlined />} onClick={doLogout}>Sair</Menu.Item>
    </Menu>
  );

  const getInitials = (name) => {
    const names = name.split(' ');
    if (names.length > 1) {
      return `${names[0][0]}${names[names.length - 1][0]}`;
    }
    return `${names[0][0]}`;
  };

  return (
    <>
      <Header
        style={{
          padding: 0,
          background: colorBgContainer,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{
            fontSize: '16px',
            width: 64,
            height: 64,
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {userNameLoading ? (

            <span style={{ marginRight: 50, fontSize: '16px' }}>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 30,
                    }}
                    spin
                  />
                }
              />
            </span>


          ) : (
            <>
              <span style={{ marginRight: 5, fontSize: '16px' }}>
                {userName}
              </span>
              <Dropdown overlay={menuOptions} trigger={['hover']}>
                <Avatar
                  style={{
                    backgroundColor: '#040350',
                    cursor: 'pointer',
                    marginLeft: 10,
                    marginRight: 42,
                  }}
                >
                  <b>{getInitials(userName)}</b>
                </Avatar>
              </Dropdown>
            </>
          )}
        </div>
      </Header>

      <Modal
        title="Terminando sessão"
        visible={modalVisible}
        footer={null}
        closable={false}
        maskClosable={false}
        bodyStyle={{ textAlign: 'center' }}
      >
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 48,
              }}
              spin
            />
          }
        />
        <br /><br />
        Por favor aguarde...
      </Modal>
    </>
  );
}

export default Navbar;
