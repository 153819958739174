import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Table, Button, Card, Skeleton, Select, Spin, Input } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { baseurl } from '../../../components/lib/settings';
import "./votingfilter.css"


const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;

const VotingFilter = () => {
  const [votingList, setVotingList] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [weekLoading, setWeekLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({
    selectedMonth: 'all',
    selectedWeek: 'all',
  });

  const [currentWeekData, setCurrentWeekData] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [weekTotalAmount, setWeekTotalAmount] = useState("");
  const [monthlyAmount, setMonthlyAmount] = useState("");
  const [accumulativeAmount, setAccumulativeAmount] = useState("");
  const [showAccumulative, setShowAccumulative] = useState(true);
  const [showMonthlyAmount, setShowMonthlyAmount] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [monthLoading, setMonthLoading] = useState(false);

  useEffect(() => {
    setLoadingTable(true);
    baseurl.get("v1/web/all_votings_all_and_filter/").then((resp) => {
      setVotingList(resp.data.filter_info);
      setAccumulativeAmount(resp.data.total_accumative_amount);
      setLoadingTable(false);
    }).catch((error) => {
      setLoadingTable(false);
    });
  }, []);

  const day = new Date();
  const current_date = day.toLocaleDateString();

  const months = Array.from({ length: day.getMonth() + 1 }, (_, i) => {
    return { value: i, label: new Date(day.getFullYear(), i, 1).toLocaleDateString('pt-BR', { month: 'long' }) };
  });

  const combineData = () => {
    const filteredData = votingList.filter(voting => {
      if (selectedFilter.selectedMonth === 'all' || selectedFilter.selectedWeek === 'all') {
        return true;
      }
      return voting.month === selectedFilter.selectedMonth && voting.week === selectedFilter.selectedWeek;
    });

    const combinedData = filteredData.map((voting, index) => ({
      order_number: index + 1,
      key: index.toString(),
      musician_name: voting.total_votes.musician.author,
      music_title: voting.total_votes.musician.title,
      voting_code: voting.total_votes.musician.voting_code,
      voting_amount: voting.total_amount,
      total_votes: voting.total_votes.total_number_votes,
    }));

    setCurrentWeekData(combinedData);
  };

  useEffect(() => {
    combineData();
  }, [votingList, selectedFilter.selectedMonth, selectedFilter.selectedWeek]);

  const columns = [
    {
      title: '#',
      dataIndex: 'order_number',
      key: 'order_number',
    },
    {
      title: 'Nome do Músico',
      dataIndex: 'musician_name',
      key: 'musician_name',
    },
    {
      title: 'Título da Música',
      dataIndex: 'music_title',
      key: 'music_title',
    },
    {
      title: 'Código de votação',
      dataIndex: 'voting_code',
      align: 'center',
      key: 'voting_code',
    },
    {
      title: 'Valor total de votos por músico',
      dataIndex: 'voting_amount',
      align: 'center',
      key: 'voting_amount',
    },
    {
      title: 'Número total de votos',
      dataIndex: 'total_votes',
      align: 'center',
      key: 'total_votes',
    },
  ];

  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.text('Votos por Período', 14, 16);
    doc.text(`Data: ${current_date}`, 14, 22);
    doc.text(`Período: ${fromDate} - ${dueDate}`, 14, 28);

    const tableColumn = columns.map(col => col.title);
    const tableRows = currentWeekData.map(item => [
      item.order_number,
      item.musician_name,
      item.music_title,
      item.voting_code,
      item.voting_amount,
      item.total_votes
    ]);

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 34,
    });

    doc.save(`votacao-${current_date}.pdf`);
  };

  const handleMonthChange = (value) => {
    setSelectedFilter({
      ...selectedFilter,
      selectedMonth: value,
    });
    setWeekLoading(true);
    setMonthLoading(true);
    setLoadingTable(true);
    const dataToSend = {
      selectedMonth: value
    };

    baseurl.post('/v1/web/musician_ranking_weeks/', dataToSend).then((resp) => {
      setFromDate(resp.data.from_date);
      setDueDate(resp.data.due_date);
      setMonthlyAmount(resp.data.total_amount_by_week);
      setShowMonthlyAmount(true);
      const combinedData = resp.data.weeks_information.map((voting, index) => ({
        order_number: index + 1,
        key: index.toString(),
        musician_name: voting.total_votes.musician.author,
        music_title: voting.total_votes.musician.title,
        voting_code: voting.total_votes.musician.voting_code,
        voting_amount: voting.total_amount,
        total_votes: voting.total_votes.total_number_votes,
      }));
      setCurrentWeekData(combinedData);
      setMonthLoading(false);
      setWeekLoading(false);
      setLoadingTable(false);
    }).catch((error) => {
    });
    setShowAccumulative(false);
  };

  const handleWeekChange = (value) => {
    setLoadingTable(true);
    setWeekLoading(true);

    setSelectedFilter({
      ...selectedFilter,
      selectedWeek: value,
    });

    if (selectedFilter.selectedMonth !== 'all' && value !== 'all') {
      const dataToSend = {
        selectedMonth: selectedFilter.selectedMonth,
        selectedWeek: value,
      };

      baseurl.post('v1/web/musician_ranking_weeks/', dataToSend)
        .then((resp) => {
          setFromDate(resp.data.from_date);
          setDueDate(resp.data.due_date);
          setWeekTotalAmount(resp.data.total_amount_by_week);
          setMonthlyAmount(resp.data.total_amount_by_week);
          const combinedData = resp.data.weeks_information.map((voting, index) => ({
            order_number: index + 1,
            key: index.toString(),
            musician_name: voting.total_votes.musician.author,
            music_title: voting.total_votes.musician.title,
            voting_code: voting.total_votes.musician.voting_code,
            voting_amount: voting.total_amount,
            total_votes: voting.total_votes.total_number_votes,
          }));
          setCurrentWeekData(combinedData);
          setLoadingTable(false);
          setWeekLoading(false);
        })
        .catch((error) => {
          setLoadingTable(false);
          setWeekLoading(false);
        });
    } else {
      setWeekTotalAmount("");
      setMonthlyAmount("");
      setShowMonthlyAmount(false);
    }
    setShowAccumulative(false);
  };

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const filteredData = currentWeekData.filter(item =>
    item.musician_name.toLowerCase().includes(searchText) ||
    item.music_title.toLowerCase().includes(searchText) ||
    item.voting_code.toLowerCase().includes(searchText)
  );

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const formatToMetical = (amount) => {
    return `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'MZN' })}`;
  };

  return (
    <Row justify="center">
      <Card
        className='divcard'
        span={24}
        style={{
          backgroundColor: 'rgb(240, 240, 240)',
          width: "100%"
        }}
      >
        <Card>
          <Col span={24}>
            <Title level={2} style={{ textAlign: 'center' }}>
              Votos por Período
            </Title>
            <div style={{ display: "flex", flexDirection: 'column', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 100%', marginBottom: '10px' }}>
                  <span>Escolha o mês&nbsp;&nbsp;</span>
                  <Select
                    style={{ width: 150, flex: '1 1 auto' }}
                    onChange={handleMonthChange}
                    defaultValue="all"
                  >
                    <Option value="all">Todos os meses</Option>
                    {months.map(month => (
                      <Option key={month.value} value={month.value.toString()}>
                        {month.label.toUpperCase()}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', flex: '1 1 100%', marginBottom: '10px' }}>
                  <span>Escolha a semana&nbsp;&nbsp;</span>
                  <Select
                    style={{ width: 150, flex: '1 1 auto' }}
                    onChange={handleWeekChange}
                    defaultValue="all"
                    disabled={selectedFilter.selectedMonth === 'all'}
                  >
                    <Option value="all">Todas as semanas</Option>
                    <Option value="1">SEMANA 1</Option>
                    <Option value="2">SEMANA 2</Option>
                    <Option value="3">SEMANA 3</Option>
                    <Option value="4">SEMANA 4</Option>
                    <Option value="5">SEMANA 5</Option>
                  </Select>
                  {weekLoading && <Spin indicator={antIcon} />}
                  {!weekLoading && <span style={{ marginLeft: '10px' }}>{fromDate} {dueDate}</span>}
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
                <span style={{ fontSize: '18px' }}>
                  {weekLoading ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <>
                      {showAccumulative ? (
                        <b>{formatToMetical(accumulativeAmount)}</b>
                      ) : (
                        <b>{selectedFilter.selectedMonth !== 'all' ? formatToMetical(monthlyAmount) : formatToMetical(weekTotalAmount)}</b>
                      )}
                    </>
                  )}
                </span>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={exportToPDF}
                  style={{ marginTop: '10px' }}
                >
                  Exportar para PDF
                </Button><br />
              </div>
            </div>
            <Search
              placeholder="Pesquisar músico ou título da música"
              onChange={(e) => handleSearch(e.target.value)}
              style={{ marginBottom: '10px', width: '100%' }}
            />
            <br />
            {loadingTable ? (
              <Skeleton active style={{ width: '100%', textAlign: 'center' }} />
            ) : (
              <div style={{ overflowX: 'auto' }}>
                <Table
                  dataSource={filteredData}
                  columns={columns}
                  pagination={false}
                />
              </div>
            )}
          </Col>
        </Card>
      </Card>
    </Row>
  );
};

export default VotingFilter;