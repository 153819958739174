import React from "react";
import { Card, Col, Row } from "antd";

import NgomaLogo from "../../assets/logos/ngoma-logo.png";
import DailyVotingList from "./charts/dailyVotingList";

function Dashboard() {
  return (

    <Row gutter={[24, 0]}>
      <Col xs={24} sm={24} md={12} lg={12} xl={16} className="mb-24">
        <DailyVotingList />
        <br />
      </Col>
    </Row>
  );
}

export default Dashboard;