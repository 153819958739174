import React, { useState } from 'react';
import { Layout } from 'antd';
import AppFooter from '../../components/footer/footer';
import Navbar from '../../components/navbar/navbar';
import NewContentLayout from './content';
import Sidebar from '../../components/sidebar/sidebar';


const NewDashboardLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Layout hasSider>
            <Sidebar collapsed={collapsed} />
            <Layout
                style={{
                    marginInlineStart: collapsed ? 80 : 200,
                    transition: 'margin 0.2s ease',
                }}
            >
                <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />
                <NewContentLayout>
                    {children}
                </NewContentLayout>
                <AppFooter />
            </Layout>
        </Layout>
    );
};

export default NewDashboardLayout;