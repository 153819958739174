import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login";
import DashboardLayout from "./pages/dashboard/dashboardLayout";
import PrivateRoutes from "./routes_private";
import NotFound404 from "./pages/404/404";
import VotingList from "./pages/mainContent/voting/votingList";
import MusicianList from "./pages/mainContent/musician/musicianList";
import CreateMusician from "./pages/mainContent/musician/createMusician";
import VotingFilter from "./pages/mainContent/musician/votingFilter";
import Dashboard from "./pages/dashboard/dashboard";
import AdminAmountReportsByDelegationCompany from "./pages/reports/AdminAmountReportsByDelegationCompany";
import VotingQuery from "./pages/reports/VotingQuery";
import DailyVotingList from "./pages/reports/DailyVotingList";
import NewDashboardLayout from "./pages/dashboard/newDashboardLayout";
import MobileDashboardLayout from "./pages/dashboard/mobileDashboardLayout.jsx";

const AppRouter = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkDevice = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkDevice();
    window.addEventListener("resize", checkDevice);

    return () => {
      window.removeEventListener("resize", checkDevice);
    };
  }, []);

  const Layout = isMobile ? MobileDashboardLayout : NewDashboardLayout;

  return (
    <Router>
      <div className="container">
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route
              path="/dashboard"
              element={
                <Layout>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/voting/all"
              element={
                <Layout>
                  <VotingList />
                </Layout>
              }
            />
            <Route
              path="/voting/category"
              element={
                <Layout>
                  <VotingFilter />
                </Layout>
              }
            />
            <Route
              element={
                <Layout>
                  <NotFound404 />
                </Layout>
              }
              path="*"
            />
            <Route
              path="/admin/musician/all"
              element={
                <Layout>
                  <MusicianList />
                </Layout>
              }
            />
            <Route
              path="/musician/all"
              element={
                <Layout>
                  <MusicianList />
                </Layout>
              }
            />
            <Route
              path="/admin/musician/create"
              element={
                <Layout>
                  <CreateMusician />
                </Layout>
              }
            />
            <Route
              path="/admin/finance/reports"
              element={
                <Layout>
                  <AdminAmountReportsByDelegationCompany />
                </Layout>
              }
            />
            <Route
              path="/admin/finance/query/votings"
              element={
                <Layout>
                  <VotingQuery />
                </Layout>
              }
            />
          </Route>
          <Route path="/" element={<Login />} exact />
        </Routes>
      </div>
    </Router>
  );
};

export default AppRouter;
