import { Layout } from 'antd';
import React from 'react'

const { Footer } = Layout;


function AppFooter() {
  const startedYear = ""
  const currentYear = new Date().getFullYear();
  const companyName = "Speranza Lda.";
  return (
    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      {startedYear} {currentYear} {companyName} Todos os direitos reservados.
    </Footer>
  )
}

export default AppFooter