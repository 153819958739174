import React, { useEffect, useState } from 'react';
import { Table, Select, Button, DatePicker, Spin, message } from 'antd';
import moment from 'moment';
import { baseurl } from '../../components/lib/settings';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;

const DailyVotingTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(moment().month() + 1);
  const [year, setYear] = useState(moment().year());

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = baseurl.post('v3/ngoma/query/daily_votings/', {
        filteredMonth: month,
        filteredYear: year,
      });
      setData(response.data);
    } catch (error) {
      message.error('Failed to fetch data');
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (value) => {
    setMonth(value);
  };

  const handleYearChange = (value) => {
    setYear(value);
  };

  const handleFetchData = () => {
    fetchData();
  };

  const columns = [
    {
      title: 'Município',
      dataIndex: 'municipio',
      key: 'municipio',
      fixed: 'left',
    },
    ...Array.from({ length: 31 }, (_, i) => ({
      title: i + 1,
      dataIndex: `day${i + 1}`,
      key: `day${i + 1}`,
    })),
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <Select defaultValue={month} onChange={handleMonthChange} style={{ width: 120, marginRight: 8 }}>
          {moment.months().map((monthName, index) => (
            <Option key={index + 1} value={index + 1}>
              {monthName}
            </Option>
          ))}
        </Select>
        <DatePicker picker="year" defaultValue={moment()} onChange={(date) => handleYearChange(date.year())} />
        <Button type="primary" onClick={handleFetchData} style={{ marginLeft: 8 }}>
          Buscar Dados
        </Button>
      </div>
      {loading ? (
        <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 40,
            }}
            spin
          />
        }
      />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          rowKey="municipio"
          scroll={{ x: 1500 }}
          summary={(pageData) => {
            let total = 0;
            pageData.forEach(({ total: rowTotal }) => {
              total += rowTotal;
            });
            return (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                {Array.from({ length: 31 }, (_, i) => (
                  <Table.Summary.Cell index={i + 1} />
                ))}
                <Table.Summary.Cell index={32}>{total}</Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      )}
    </div>
  );
};

export default DailyVotingTable;
