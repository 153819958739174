import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Typography, Table, Button, Card, Skeleton, Spin, Input } from 'antd';
import { DownloadOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { baseurl } from '../../../components/lib/settings';
import "./votingList.css"


const { Title } = Typography;

const VotingList = () => {
  const [votingList, setVotingList] = useState([]);
  const [filteredVotingList, setFilteredVotingList] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [weekAmount, setWeekAmount] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setLoadingTable(true);
    baseurl.get('v1/web/all_votings_by_musician/')
      .then((resp) => {
        const weeksInformation = resp.data[1]?.weeks_information || [];
        setVotingList(weeksInformation);
        setFilteredVotingList(weeksInformation);
        setFromDate(resp.data[1].from_date);
        setDueDate(resp.data[1].due_date);
        setWeekAmount(resp.data[1].total_amount_by_week);
        setLoadingTable(false);
      })
      .catch((error) => {
        setLoadingTable(false);
      });
  }, []);

  const day = new Date();
  const current_date = day.toLocaleDateString();

  const [data, setData] = useState([]);

  const combineData = useCallback((list) => {
    if (!Array.isArray(list)) {
      return;
    }

    const combinedData = list.map((voting, index) => ({
      order_number: index + 1,
      key: index.toString(),
      musician_name: voting.total_votes.musician.author,
      music_title: voting.total_votes.musician.title,
      voting_code: voting.total_votes.musician.voting_code,
      voting_amount: voting.total_amount,
      total_votes: voting.total_votes.total_number_votes,
    }));

    setData(combinedData);
  }, []);

  useEffect(() => {
    combineData(votingList);
  }, [votingList, combineData]);

  useEffect(() => {
    const filteredData = votingList.filter((voting) =>
      Object.values(voting.total_votes.musician).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      ) ||
      voting.total_amount.toString().toLowerCase().includes(searchText.toLowerCase()) ||
      voting.total_votes.total_number_votes.toString().toLowerCase().includes(searchText.toLowerCase())
    );
    combineData(filteredData);
  }, [searchText, votingList, combineData]);

  const columns = [
    {
      title: '#',
      dataIndex: 'order_number',
      key: 'order_number',
    },
    {
      title: 'Nome do Músico',
      dataIndex: 'musician_name',
      key: 'musician_name',
    },
    {
      title: 'Título da Música',
      dataIndex: 'music_title',
      key: 'music_title',
    },
    {
      title: 'Código de votação',
      dataIndex: 'voting_code',
      align: 'center',
      key: 'voting_code',
    },
    {
      title: 'Valor total de votos por músico',
      dataIndex: 'voting_amount',
      align: 'center',
      key: 'voting_amount',
    },
    {
      title: 'Número total de votos',
      dataIndex: 'total_votes',
      align: 'center',
      key: 'total_votes',
    },
  ];

  const exportToPDF = () => {
    const doc = new jsPDF();

    doc.text('Total de votos por músico', 14, 22);
    doc.text(`De ${fromDate} ${dueDate}`, 14, 36);
    doc.text(`Data: ${current_date}`, 14, 29);

    autoTable(doc, {
      startY: 40,
      head: [['#', 'Nome do Músico', 'Título da Música', 'Código de votação', 'Valor total de votos por músico', 'Número total de votos']],
      body: data.map(item => [item.order_number, item.musician_name, item.music_title, item.voting_code, item.voting_amount + " MT", item.total_votes])
    });

    doc.save(`votacao-${current_date}.pdf`);
  };

  const formatToMetical = (amount) => {
    return amount !== undefined && amount !== null
      ? `${amount.toLocaleString('pt-BR', { style: 'currency', currency: 'MZN' })}`
      : "0,00 MT";
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  return (
    <Row justify="center">
      <Card className='divcard' span={24} style={{ width: "100%" }}>
        <Card>
          <Col span={24}>
            <Title level={2} style={{ textAlign: 'center' }}>Total de votos por músico</Title>
            <div className="responsive-header">
              <div>
                <br />
                {loadingTable ? <Spin indicator={<LoadingOutlined />} /> : <b>De {fromDate} até {dueDate}</b>}
              </div>
              <div className="responsive-total">
                <span style={{ fontSize: '18px' }}>
                  {loadingTable ? <Spin indicator={<LoadingOutlined />} /> : <b>{formatToMetical(weekAmount)}</b>}
                </span>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={exportToPDF}
                  className="responsive-button"
                >
                  Exportar para PDF
                </Button>
              </div>
            </div>
            <Input
              placeholder="Buscar"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              prefix={<SearchOutlined />}
              className="responsive-input"
            />

            {loadingTable ? (
              <Skeleton active style={{ width: '100%', textAlign: 'center' }} />
            ) : (
              <div style={{ overflowX: 'auto' }}>
                <br />
                <Table
                  dataSource={data}
                  columns={columns}
                  pagination={{ pageSize: 100 }}
                  style={{ minWidth: '600px' }}
                />
              </div>
            )}
          </Col>
        </Card>
      </Card>
    </Row>
  );
};

export default VotingList;
