import React, { useState, useEffect } from 'react';
import { Table, Skeleton, Card, Divider, Typography, Spin, Button, Input, Switch, message } from 'antd';
import { baseurl } from '../../../components/lib/settings';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { DownloadOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import "./MusicianList.css"

const { Title } = Typography;

const MusicianList = () => {
  const [musicianList, setMusicianList] = useState([]);
  const [filteredMusicianList, setFilteredMusicianList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState({ description: '' });
  const [searchText, setSearchText] = useState('');
  const current_date = new Date().toLocaleDateString();


  const handleToggleActive = (id, isAvailableToVote) => {
    const data = {
      id: id,
      is_available_to_vote: isAvailableToVote
    };

    baseurl.post('v3/ngoma/musician/activate_by_id/', data)
      .then(() => {
        message.success(`Músico ${isAvailableToVote ? 'ativado' : 'desativado'} com sucesso!`);
        setMusicianList((prevList) =>
          prevList.map((musician) =>
            musician.id === id ? { ...musician, available_to_vote: isAvailableToVote } : musician
          )
        );
        setFilteredMusicianList((prevList) =>
          prevList.map((musician) =>
            musician.id === id ? { ...musician, available_to_vote: isAvailableToVote } : musician
          )
        );
      })
      .catch(() => {
        message.error('Erro ao atualizar o status do músico');
      });
  };


  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Autor',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Código de Votação',
      dataIndex: 'voting_code',
      key: 'voting_code',
    },
    {
      title: 'Contacto',
      dataIndex: 'contact',
      key: 'contact',
    },
    {
      title: 'Número de Whatsapp',
      dataIndex: 'whatsapp_number',
      key: 'whatsapp_number',
    },

    {
      title: 'Estado',
      dataIndex: 'available_to_vote',
      key: 'available_to_vote',
      render: (isAvailableToVote, record) => (
        <Switch
          checked={isAvailableToVote}
          onChange={(checked) => handleToggleActive(record.id, checked)}
        />
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (record.available_to_vote ? <b>ACTIVA</b> : <>Eliminada</>),
    }
  ];

  useEffect(() => {
    setIsLoading(true);
    baseurl.post('v2/ngoma/musicians_by_company/').then((resp) => {
      setMusicianList(resp.data);
      setFilteredMusicianList(resp.data);
      setIsLoading(false);
    })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const filteredData = musicianList.filter((musician) =>
      Object.values(musician).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredMusicianList(filteredData);
  }, [searchText, musicianList]);

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableColumn = columns.slice(0, -1).map(col => col.title);
    const tableRows = filteredMusicianList.map((musician, index) => {
      return [
        index + 1,
        musician.author,
        musician.title,
        musician.voting_code,
        musician.contact,
        musician.whatsapp_number,
        musician.available_to_vote ? 'ACTIVA' : 'Eliminada'
      ];
    });

    doc.text(`Data: ${current_date}`, 14, 16);
    doc.text(`Lista de Canções - ${company.description}`, 14, 22);

    doc.autoTable({
      startY: 30,
      head: [tableColumn],
      body: tableRows,
      styles: {
        cellPadding: 3,
        fontSize: 10,
      },
      didParseCell: (data) => {
        if (data.cell.raw === 'ACTIVA') {
          data.cell.styles.fontStyle = 'bold';
        } else if (data.cell.raw === 'Eliminada') {
          data.cell.styles.fontStyle = 'normal';
        }
      },
    });

    doc.save(`musician_details_${company.description}.pdf`);
  };


  return (
    <div style={{ padding: "20px" }}>
      <Title
        level={2}
        style={{
          textAlign: "center",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "normal",
          maxWidth: "100%",
        }}
      >
        Lista de músicos registados
        {isLoading && (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 25 }} spin />}
          />
        )}
        {!isLoading && ` ${company.description}`}
      </Title>

      {isLoading ? (
        <Skeleton active style={{ width: '100%', textAlign: 'center' }} />
      ) : (
        <Card style={{ backgroundColor: "#eeeeee" }}>
          <Divider orientation='right'>
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={exportToPDF}
              style={{ marginBottom: '10px' }}
            >
              Exportar para PDF
            </Button>
          </Divider>
          <Input
            placeholder="Buscar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            prefix={<SearchOutlined />}
            style={{ marginBottom: '10px', width: '100%' }}
          />
          <div style={{ overflowX: 'auto' }}>

            <Table
              columns={columns}
              dataSource={filteredMusicianList.map((musician, index) => ({ ...musician, key: index }))}
              pagination={{ pageSize: 100 }}
              style={{ minWidth: '100%' }}

            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default MusicianList;
