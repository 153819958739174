import React from 'react';
import { Link } from 'react-router-dom';

function NotFound404() {
  return (
    <div style={styles.container}>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="200"
          height="200"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="8" x2="12" y2="12" />
          <line x1="12" y1="16" x2="12" y2="16" />
        </svg>
      </div>
      <h1 style={styles.heading}>Página não encontrada</h1>
      <p style={styles.text}>
        A página que está procurando não existe.{' '}
        <Link to="/voting/all" style={styles.link}>
          Voltar para a página inicial
        </Link>
      </p>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh',
  },
  heading: {
    fontSize: '24px',
    marginBottom: '16px',
  },
  text: {
    fontSize: '16px',
    textAlign: 'center',
  },
  link: {
    color: 'blue',
    textDecoration: 'underline',
  },
};

export default NotFound404;