import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  const token = localStorage.getItem("token");

  if (token) {
    const tokenIsValid = true;

    if (tokenIsValid) {
      return <Outlet />;
    } else {
      return (
        <>
          <Navigate to="/login" />
        </>
      );
    }
  } else {
    return (
      <>
        <Navigate to="/login" />
      </>
    );
  }
};

export default PrivateRoutes;
